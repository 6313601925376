import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "error404",
    meta: {
      auth: false,
    },
    component: () => import("../views/error/404.vue"),
  },
  {
    path: "/registro",
    name: "registro",
    meta: {
      auth: false,
    },
    component: () => import("../views/registro/Index.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
    },
    component: () => import("../views/login/Index.vue"),
  },
  {
    path: "/recuperar-clave",
    name: "recuperarClave",
    meta: {
      auth: false,
    },
    component: () => import("../views/recuperarClave/Index.vue"),
  },
  {
    path: "/seleccionar-empresa",
    name: "seleccionarEmpresa",
    meta: {
      auth: true,
    },
    component: () => import("../views/seleccionEmpresa/Index.vue"),
  },
  {
    path: "/",
    redirect: "/inicio",
    component: () => import("../views/Template.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/inicio",
        name: "inicio",
        meta: {
          auth: true,
        },
        component: () => import("../views/inicio/Index.vue"),
      },

      {
        path: "/mi-perfil",
        name: "miPerfil",
        meta: {
          auth: true,
        },
        component: () => import("../views/miPerfil/Index.vue"),
      },

      {
        path: "/configuracion",
        name: "configuracion",
        meta: {
          auth: true,
        },
        component: () => import("../views/configuracion/Index.vue"),
      },

      {
        path: "/empresas",
        name: "empresas",
        meta: {
          auth: true,
        },
        component: () => import("../views/empresas/Index.vue"),
      },
      {
        path: "/empresas/crear",
        name: "empresasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/empresas/Crear.vue"),
      },
      {
        path: "/empresas/:idempresa/editar",
        name: "empresasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/empresas/Editar.vue"),
      },

      {
        path: "/usuarios",
        name: "usuarios",
        meta: {
          auth: true,
        },
        component: () => import("../views/usuarios/Index.vue"),
      },
      {
        path: "/usuarios/crear",
        name: "usuariosCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/usuarios/Crear.vue"),
      },
      {
        path: "/usuarios/:idusuario/editar",
        name: "usuariosEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/usuarios/Editar.vue"),
      },

      {
        path: "/roles",
        name: "roles",
        meta: {
          auth: true,
        },
        component: () => import("../views/roles/Index.vue"),
      },
      {
        path: "/roles/crear",
        name: "rolesCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/roles/Crear.vue"),
      },
      {
        path: "/roles/:idrol/editar",
        name: "rolesEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/roles/Editar.vue"),
      },

      {
        path: "/categorias",
        name: "categorias",
        meta: {
          auth: true,
        },
        component: () => import("../views/categorias/Index.vue"),
      },
      {
        path: "/categorias/crear",
        name: "categoriasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/categorias/Crear.vue"),
      },
      {
        path: "/categorias/:idcategoria/editar",
        name: "categoriasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/categorias/Editar.vue"),
      },

      {
        path: "/marcas",
        name: "marcas",
        meta: {
          auth: true,
        },
        component: () => import("../views/marcas/Index.vue"),
      },
      {
        path: "/marcas/crear",
        name: "marcasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/marcas/Crear.vue"),
      },
      {
        path: "/marcas/:idmarca/editar",
        name: "marcasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/marcas/Editar.vue"),
      },

      {
        path: "/clientes",
        name: "clientes",
        meta: {
          auth: true,
        },
        component: () => import("../views/clientes/Index.vue"),
      },
      {
        path: "/clientes/crear",
        name: "clientesCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/clientes/Crear.vue"),
      },
      {
        path: "/clientes/:idcliente/editar",
        name: "clientesEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/clientes/Editar.vue"),
      },

      {
        path: "/proveedores",
        name: "proveedores",
        meta: {
          auth: true,
        },
        component: () => import("../views/proveedores/Index.vue"),
      },
      {
        path: "/proveedores/crear",
        name: "proveedoresCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/proveedores/Crear.vue"),
      },
      {
        path: "/proveedores/:idproveedor/editar",
        name: "proveedoresEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/proveedores/Editar.vue"),
      },

      {
        path: "/productos",
        name: "productos",
        meta: {
          auth: true,
        },
        component: () => import("../views/productos/Index.vue"),
      },
      {
        path: "/productos/crear",
        name: "productosCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/productos/Crear.vue"),
      },
      {
        path: "/productos/:idproducto/editar",
        name: "productosEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/productos/Editar.vue"),
      },

      {
        path: "/cotizaciones",
        name: "cotizaciones",
        meta: {
          auth: true,
        },
        component: () => import("../views/cotizaciones/Index.vue"),
      },
      {
        path: "/cotizaciones/crear",
        name: "cotizacionesCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/cotizaciones/Crear.vue"),
      },
      {
        path: "/cotizaciones/:idcotizacion/editar",
        name: "cotizacionesEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/cotizaciones/Editar.vue"),
      },
      {
        path: "/cotizaciones/:idcotizacion/visualizar",
        name: "cotizacionesVisualizar",
        meta: {
          auth: true,
        },
        component: () => import("../views/cotizaciones/Visualizar.vue"),
      },
      {
        path: "/cotizaciones-aprobadas",
        name: "cotizacionesAprobadas",
        meta: {
          auth: true,
        },
        component: () => import("../views/cotizacionesAprobadas/Index.vue"),
      },
      {
        path: "/cotizaciones-aprobadas/:idcotizacion/visualizar",
        name: "cotizacionesAprobadasVisualizar",
        meta: {
          auth: true,
        },
        component: () =>
          import("../views/cotizacionesAprobadas/Visualizar.vue"),
      },

      {
        path: "/compras",
        name: "compras",
        meta: {
          auth: true,
        },
        component: () => import("../views/compras/Index.vue"),
      },
      {
        path: "/compras/crear",
        name: "comprasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/compras/Crear.vue"),
      },
      {
        path: "/compras/:idcompra/editar",
        name: "comprasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/compras/Editar.vue"),
      },

      {
        path: "/ingresos",
        name: "ingresos",
        meta: {
          auth: true,
        },
        component: () => import("../views/ingresos/Index.vue"),
      },
      {
        path: "/ingresos/crear",
        name: "ingresosCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/ingresos/Crear.vue"),
      },
      {
        path: "/ingresos/:idingreso/editar",
        name: "ingresosEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/ingresos/Editar.vue"),
      },

      {
        path: "/ventas",
        name: "ventas",
        meta: {
          auth: true,
        },
        component: () => import("../views/ventas/Index.vue"),
      },
      {
        path: "/ventas/crear",
        name: "ventasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/ventas/Crear.vue"),
      },
      {
        path: "/ventas/:idventa/editar",
        name: "ventasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/ventas/Editar.vue"),
      },
      {
        path: "/ventas/:idventa/visualizar",
        name: "ventasVisualizar",
        meta: {
          auth: true,
        },
        component: () => import("../views/ventas/Visualizar.vue"),
      },
      {
        path: "/ventas-anuladas",
        name: "ventasAnuladas",
        meta: {
          auth: true,
        },
        component: () => import("../views/ventas/Anuladas.vue"),
      },

      {
        path: "/guias",
        name: "guias",
        meta: {
          auth: true,
        },
        component: () => import("../views/guias/Index.vue"),
      },
      {
        path: "/guias/crear",
        name: "guiasCrear",
        meta: {
          auth: true,
        },
        component: () => import("../views/guias/Crear.vue"),
      },
      {
        path: "/guias/:idguia/editar",
        name: "guiasEditar",
        meta: {
          auth: true,
        },
        component: () => import("../views/guias/Editar.vue"),
      },

      {
        path: "/reporte-stock",
        name: "reporteStock",
        meta: {
          auth: true,
        },
        component: () => import("../views/reporteStock/Index.vue"),
      },

      {
        path: "/kardex",
        name: "kardex",
        meta: {
          auth: true,
        },
        component: () => import("../views/kardex/Index.vue"),
      },

      {
        path: "/auditorias",
        name: "auditorias",
        meta: {
          auth: true,
        },
        component: () => import("../views/auditoria/Index.vue"),
      },

      {
        path: "/cobranzas",
        name: "cobranzas",
        meta: {
          auth: true,
        },
        component: () => import("../views/cobranzas/Index.vue"),
      },

      {
        path: "/notaCredito",
        name: "notaCredito",
        meta: {
          auth: true,
        },
        component: () => import("../views/notaCredito/Index.vue"),
      },

      {
        path: "/notaCredito/crear/:idventa?",
        name: "notaCredito.crear",
        meta: {
          auth: true,
        },
        component: () => import("../views/notaCredito/Crear.vue"),
      },

      {
        path: "/trazabilidad-compra-venta",
        name: "trazabilidadCompraVenta.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/trazabilidadCompraVenta/Index.vue"),
      },

      {
        path: "/crm-clientes",
        name: "crmClientes.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmCliente/Index.vue"),
      },

      {
        path: "/crm-clientes/crear",
        name: "crmClientes.crear",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmCliente/Crear.vue"),
      },
      {
        path: "/crm-clientes/:id/editar",
        name: "crmClientes.editar",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmCliente/Editar.vue"),
      },

      {
        path: "/crm-orden-cotizacion",
        name: "crmOrdenCotizacion.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmOrdenCotizacion/Index.vue"),
      },
      {
        path: "/crm-orden-cotizacion/crear",
        name: "crmOrdenCotizacion.crear",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmOrdenCotizacion/Crear.vue"),
      },
      {
        path: "/crm-orden-cotizacion/:idcotizacion/editar",
        name: "crmOrdenCotizacion.editar",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmOrdenCotizacion/Editar.vue"),
      },
      {
        path: "/crm-orden-cotizacion/:idcotizacion/visualizar",
        name: "crmOrdenCotizacion.visualizar",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmOrdenCotizacion/Visualizar.vue"),
      },

      {
        path: "/crm-visita-cliente",
        name: "crmVisitaCliente.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmVisitaCliente/Index.vue"),
      },
      {
        path: "/crm-visita-cliente/crear",
        name: "crmVisitaCliente.crear",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmVisitaCliente/Crear.vue"),
      },
      {
        path: "/crm-visita-cliente/:id/editar",
        name: "crmVisitaCliente.editar",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmVisitaCliente/Editar.vue"),
      },

      {
        path: "/crm-cotizaciones-vendedor",
        name: "crmCotizacionesVendedor.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/crmCotizacionVendedor/Index.vue"),
      },

      {
        path: "/kardex-ventas",
        name: "kardexVentas.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/kardexVentas/Index.vue"),
      },

      {
        path: "/kardex-valorizado",
        name: "kardexValorizado.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/kardexValorizado/Index.vue"),
      },

      {
        path: "/reporte-ventas-compras",
        name: "reporteVentaCompra.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/reporteVentaCompra/Index.vue"),
      },

      {
        path: "/codigo-comprobantes",
        name: "codigoComprobante.index",
        meta: {
          auth: true,
        },
        component: () => import("../views/codigoComprobante/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  // process.env.NODE_ENV === "production" ? "/cemedic/" : "/"
  /*history: createWebHistory(),*/
  routes,
});

//protegiendo rutas
router.beforeEach((to, from, next) => {
  let token = store.state.login.token;
  let empresa = store.state.empresa.empresa;

  if (to.meta.auth) {
    if (token != null && token != "" && token != undefined) {
      if (Object.keys(empresa).length == 0 && to.name != "seleccionarEmpresa") {
        next({ name: "seleccionarEmpresa" });
      } else {
        next();
      }
    } else {
      next({ name: "login" });
    }
  } else {
    if (
      token != null &&
      token != "" &&
      token != undefined &&
      (to.path == "/" ||
        to.name == "registro" ||
        to.name == "login" ||
        to.name == "recuperarClave")
    ) {
      next({ name: "inicio" });
    } else {
      next();
    }
  }
});

export default router;
