import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import login from './login'
import template from './template'
import configuracion from './configuracion'
import empresa from './empresa'
import guia from './guia'
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login,
    template,
    configuracion,
    empresa,
    guia
  },
  plugins: [new VuexPersistence({
    key:process.env.VUE_APP_NAME,
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  }).plugin]
})
